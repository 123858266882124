import { GET_CURRENT_PERCENTAGE } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_PERCENTAGE:
      return { 
        percentage: action.payload
      };
    default:
      return state;
  }
};