import {GET_CURRENT_PERCENTAGE} from './types';

export function setPercentage(percentage) {

  return dispatch => {
    dispatch({
      type: GET_CURRENT_PERCENTAGE,
      payload: percentage
    });
  } 
}