import React from 'react';
import { DateInput } from '@progress/kendo-react-dateinputs';
import Ticks from './Ticks';
import { editGoal, deleteGoal, completeGoal, addTick } from '../../actions/goals';
import { connect } from 'react-redux';
import { CancelIcon, EditIcon, SendIcon, TrashIcon } from '../../shared/Icons';
import ButtonImg from '../../shared/ButtonImg';
import { fbToJsDate, getDateString } from '../../shared/helpers';
import GoalItemTick from './GoalItemTick';

class GoalItem extends React.Component {
  constructor(props) {
    super(props);

    const { goal } = props;
    this.state = {
      expanded: false,
      edit: false,
      goalID: goal.goalID,
      title: goal.title,
      description: goal.description,
      dueDate: fbToJsDate(goal.dueDate),
      deleted: false,
      isDirty: false
    }

    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.goal.goalID !== this.props.goal.goalID) {
      this.setState({
        goalID: this.props.goalID,
        title: this.props.goal.title,
        description: this.props.goal.description,
        dueDate: fbToJsDate(this.props.goal.dueDate)
      })
    }
  }

  submitDeleteGoal = (e) => {
    e.preventDefault();
    this.props.deleteGoal(this.props.goal.goalID);
    this.setState({
      edit: false,
      expanded: false,
      deleted: true
    })
  }

  submitCompleteGoal = (e) => {
    e.preventDefault();
    this.props.completeGoal(this.props.goal.goalID);
    this.setState({
      edit: false,
      expanded: false,
      completed: true
    })
  }

  submitAddActivity = (e) => {
    e.preventDefault();
    this.props.addTick(this.props.goal.goalID);
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
      edit: false
    })
  }

  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  submitEditGoal = e => {
    e.preventDefault();
    const { editGoal } = this.props;
    editGoal({
      goalID: this.props.goal.goalID,
      title: this.state.title,
      description: this.state.description,
      dueDate: this.state.dueDate
    });
    this.toggleEdit()
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      isDirty: this.checkIsDirty(e.target.name, e.target.value)
    });
  }

  checkIsDirty(editedFieldName, editedFieldValue) {
    const fields = ['goalID', 'title', 'description', 'dueDate'];
    return fields.reduce((aggr, field) => {
      let fieldIsDirty = false;

      let currentValue = this.state[field];
      if (field === editedFieldName) {
        currentValue = editedFieldValue;
      }

      if (field === 'dueDate') {
        fieldIsDirty = Math.round(currentValue.getTime() / 1000) !== this.props.goal.dueDate.seconds;
      } else {
        fieldIsDirty = currentValue !== this.props.goal[field];
      }
      return aggr || fieldIsDirty
    }, false);
  }

  getActivity() {
    const createdDate = fbToJsDate(this.props.goal.createdDate);
    const { goal } = this.props;
    console.log(goal)
    return (
      <div className="goal-grid">
        <ul className="activity-list grid-right">
          <li className="top-actions activity">
            <span className="">
              <button className="text" onClick={this.submitAddActivity}>
                add activity
              </button>
              &nbsp;or&nbsp;
              <button className="text" onClick={this.submitCompleteGoal}>
                mark as complete
              </button>
            </span>
          </li>
          {
            goal.ticks && goal.ticks.map((tick) => <GoalItemTick key={tick.id} tick={tick} />).reverse()
          }
          <li className="created">
            <span className="">created {getDateString(createdDate, "long")}</span>
          </li>
        </ul>
      </div>
    )
  }

  getActionButtons() {
    let editButton = null,
      cancelButton = null,
      saveButton = null,
      deleteButton = null,
      expandButton = null;

    if (this.state.expanded && !this.state.edit) {
      editButton = <ButtonImg buttonClass="edit-button" icon={<EditIcon title='edit goal' />} clickHandler={this.toggleEdit} />
    }

    if (this.state.edit) {
      cancelButton = <ButtonImg icon={<CancelIcon title='cancel goal' />} labelText='Cancel' clickHandler={this.toggleEdit} />
      saveButton = <ButtonImg icon={<SendIcon title='add goal' />} labelText='Save' clickHandler={this.submitEditGoal} disabled={!this.state.isDirty} />
      deleteButton = <ButtonImg icon={<TrashIcon title='delete goal' />} labelText='Delete' clickHandler={this.submitDeleteGoal} />
    }

    if (!this.state.edit) {
      expandButton =
        <button className="expand-button">
          <img onClick={this.toggleExpanded} src={this.state.expanded ? '/img/collapse.png' : '/img/expand.png'} alt='expand icon'></img>
        </button>
    }

    return (
      <div className="buttons">
        <div className="top">
          {editButton}
          {cancelButton}
          {saveButton}
          {deleteButton}
        </div>
        {expandButton}
      </div>
    )
  }

  getContent() {
    const { goal } = this.props;
    const { ticks } = goal;
    const dueDate = new Date(this.state.dueDate);
    const dueDateString = getDateString(dueDate);

    if (this.state.expanded) {
      return (
        <>
          <div className="goal-grid multi">
            <div className="grid-left-top">
              {
                this.state.edit ? (
                  <DateInput className="date-input"
                    value={dueDate}
                    onChange={this.updateInput}
                    name="dueDate"
                  />
                ) : dueDateString
              }
            </div>

            <span className="grid-right-top title">
              {
                this.state.edit ? (
                  <input
                    className="edit-goal-title"
                    type="text"
                    name="title"
                    onChange={this.updateInput}
                    value={this.state.title}
                  />
                ) : goal.title
              }
            </span>

            <span className="grid-right-bottom">
              {
                this.state.edit ? (
                  <input
                    className="edit-goal-description"
                    type="text"
                    name="description"
                    onChange={this.updateInput}
                    value={this.state.description}
                    placeholder="Add description here."
                  />
                ) : (
                    goal.description || <span className="placeholder">Edit goal to add a description.</span>
                  )
              }
            </span>
          </div>
          {this.getActivity()}
        </>
      )
    } else {
      return (
        <div className="goal-grid">
          <Ticks className="grid-left" goalID={goal.goalID} ticks={ticks} />
          <div className="goal grid-right">
            <div className="title">
              <span>{goal.title}</span>
            </div>
          </div>
        </div>
      )
    }
  }


  render() {
    const { goal } = this.props
    let classNames = 'goalListItem ';

    if (this.state.expanded) {
      classNames += 'expanded ';
    }

    if (this.props.goal.completed || this.state.completed) {
      classNames += 'completed ';
    }

    return (
      <div key={goal.goalID} className={classNames}>
        {this.getContent()}
        {this.getActionButtons()}
      </div>
    )
  }
}

export default connect(null, { deleteGoal, editGoal, completeGoal, addTick })(GoalItem);