import React from 'react';
import paper from 'paper';
import { withRouter } from 'react-router-dom';


class TodayBlob extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToday: props.location.pathname === '/' ? true : false,
      lastPath: '' 
    };
  }
  
  componentDidUpdate() {
    console.log(this.state.isToday);
  }

  componentDidMount() {
    this.props.history.listen((location) => {
      let newState = {};
      if( location.pathname !== this.state.lastPath ){
        newState.lastPath = location.pathname;
      }

      if( location.pathname === '/' ){
        newState.isToday = true;
      }
      this.setState(newState);
    });

    var blob = new paper.PaperScope();
	  blob.setup(document.getElementById('todayBlobCanvas'));
    var view = blob.view,
        Point = blob.Point,
        Path = blob.Path,
        Group = blob.Group;

    // adjustable variables
    var mouseForce = 0.4;
    var startingHeight = 0.04; // 0.1 == 10%
    var startingX = 0.76; // 0.5 == 50%
    var radiusPercOfWidth = 0.8;
    var blobColor = "#111a3d";
    // other variables
    var mousePoint = new Point(-1000, -1000);

    function Bacterium(center, size, color) {
      this.build(center, size, color);
    }

    Bacterium.prototype = {
      build: function(center, radius, color) {
  
        var padding = Math.min(view.size.width, view.size.height) * 0.2;
        var maxWidth = view.size.width - padding * 2;
        var maxHeight = view.size.height - padding * 2;
        var w = maxWidth;
        var h = maxHeight;
  
        this.fitRect = new Path.Rectangle({
          point: [view.size.width / 2 - w / 2, view.size.height / 2 - h / 2],
          size: [w, h]
        });
  
        this.circlePath = new Path.Circle(center, radius);
  
        this.group = new Group([this.circlePath]);
        this.group.position = center;
  
        this.circlePath.fillColor = color;
        this.circlePath.fullySelected = false;
  
        // Mausdistanz
        this.threshold = radius * 1.4;
        this.center = center;
        // Elemente hinzufügen
        this.circlePath.flatten(radius * 1.5);
        // wieder zum Kreis machen
        this.circlePath.smooth();
        // einpassen in das fitRect
        // this.circlePath.fitBounds( this.fitRect.bounds );
  
        // control circle erstellen, auf den die einzelnen Punkte später zurückgreifen können
        this.controlCircle = this.circlePath.clone();
        this.controlCircle.fullySelected = false;
        this.controlCircle.visible = false;
  
        var rotationMultiplicator = radius / 200;
  
        // Settings pro segment
        this.settings = [];
        for( var i = 0; i < this.circlePath.segments.length; i++ ) {
          var segment = this.circlePath.segments[i];
          this.settings[i] = {
            relativeX: segment.point.x - this.center.x,
            relativeY: segment.point.y - this.center.y,
            offsetX: rotationMultiplicator,
            offsetY: rotationMultiplicator,
            momentum: new Point(0,0)
          };
        }
      },
      clear: function() {
        this.circlePath.remove();
        this.fitRect.remove();
      },
      animate: function(event, center) {
  
        this.group.rotate(-0.2, center);
  
        for( var i = 0; i < this.circlePath.segments.length; i++ ) {
          var segment = this.circlePath.segments[i];
  
          var settings = this.settings[i];
          var controlPoint = new Point(
            //settings.relativeX + this.center.x,
            //settings.relativeY + this.center.y
          );
          controlPoint = this.controlCircle.segments[i].point;
  
          // Avoid the mouse
          var mouseOffset = mousePoint.subtract(controlPoint);
          var mouseDistance = mousePoint.getDistance( controlPoint );
          var newDistance = 0;
  
          if( mouseDistance < this.threshold ) {
            newDistance = (mouseDistance - this.threshold) * mouseForce;
          }
  
          var newOffset = new Point(0, 0);
          if(mouseDistance !== 0){
            newOffset = new Point(mouseOffset.x / mouseDistance * newDistance, mouseOffset.y / mouseDistance * newDistance);
          }
          var newPosition = controlPoint.add( newOffset );
  
          var distanceToNewPosition = segment.point.subtract( newPosition );
  
          settings.momentum = settings.momentum.subtract( distanceToNewPosition.divide( 6 ) );
          settings.momentum = settings.momentum.multiply( 0.6 );
  
          // Add automatic rotation
  
          var amountX = settings.offsetX;
          var amountY = settings.offsetY;
          var sinus = Math.sin(event.time + i*2);
          var cos =  Math.cos(event.time + i*2);
          settings.momentum = settings.momentum.add( new Point(cos * -amountX, sinus * -amountY) );
  
          // go to the point, now!
          segment.point = segment.point.add( settings.momentum );
  
        }
      }
    };

    var radius = (view.size.width/2) * radiusPercOfWidth;
    var startingPoint = new Point(startingX * view.bounds.width, startingHeight * view.bounds.height);
    var bacterium = new Bacterium( startingPoint, radius, blobColor );

    view.onFrame = function(event) {
      bacterium.animate(event, startingPoint);
    };

    var tool = new blob.Tool();
    tool.onMouseMove = function(event) {
      mousePoint = event.lastPoint;
    };
  }

  render() {
    let canvasHeight = 100;
    let transformPercentage = 0;
    
    const canvasStyle = {
      transform: 'translateY(' + transformPercentage + '%)',
      height: canvasHeight + '%',
      width: '100%'
    };

    return (
      <canvas id="todayBlobCanvas" resize="true" style={canvasStyle}></canvas>
    );
  }
}

export default withRouter(TodayBlob);