import React from 'react';

// import { AuthUserContext, withAuthorization } from '../Session';


const AccountPage = () => (
  // <AuthUserContext.Consumer>
  //   {authUser => (
  //     <div>
  //       <h1>Account: {authUser.email}</h1>
  //       <h1>Account: {authUser.roles}</h1>
  //     </div>
  //   )}
  // </AuthUserContext.Consumer>
  <div>hi</div>
);

// const condition = authUser => !!authUser;

export default AccountPage;
