import { goalsRef, ticksRef } from "../config/firebase";
import { FETCH_GOALS_BY_WORKSPACE } from "./types";

export const addGoal = newGoal => async () => {
  goalsRef.add(newGoal)
    .then(() => {
      console.log('added');
    })
    .catch(error => {
      console.error(error);
    });
};

export const editGoal = goalData => async () => {

  goalsRef.doc(goalData.goalID).update({
    title: goalData.title,
    description: goalData.description,
    dueDate: goalData.dueDate
  })
    .then(() => {
      console.log('edited');
    })
}
export const deleteGoal = goalID => async () => {
  goalsRef.doc(goalID).update({
    deleted: true
  })
}
export const completeGoal = goalID => async () => {
  goalsRef.doc(goalID).update({
    completed: true,
    completedDate: Date.now()
  })
}
export const fetchGoalsByWorkspace = workspaceRef => async dispatch => {
  goalsRef.where('workspace', '==', workspaceRef).where("deleted", "==", false).orderBy('dueDate', 'asc').onSnapshot(querySnapshot => {
    const goals = [];
    querySnapshot.forEach((doc) => {
      let goalToAdd = doc.data();
      goalToAdd.goalID = doc.id;
      goals.push(goalToAdd);
    });

    dispatch({
      type: FETCH_GOALS_BY_WORKSPACE,
      payload: goals
    });
  });
};

export const addTick = (goalID) => async () => {
  goalsRef.doc(goalID).get().then(goalDocRef => {
    ticksRef.add({
      goal: goalDocRef.ref,
      date: new Date(),
      description: '',
      user: ''
    }).then(tickDocRef => {
      let newTicks = goalDocRef.data().ticks
      newTicks.push(tickDocRef);

      goalsRef.doc(goalID).update({
        ticks: newTicks
      });
    });
  })
};

export const getTick = (tickRef) => () => {
  return tickRef.get();
}