import { combineReducers } from "redux";

import goals from "./goalsReducer";
import auth from './authReducer';
import dayStats from './dayStatsReducer';

export default combineReducers({
  goals,
  auth,
  dayStats
});