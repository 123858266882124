import React from 'react';
import { connect } from 'react-redux';
import { setPercentage }  from '../../actions/today';
import PercentageBoxPaperBackground from './PercentageBoxPaperBackground'

class PercentageBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      type: 'year'
    }
  }

  setType (typeString) {
    this.setState({
      type: typeString
    })
  }
  
  render (){
    const currentYear = this.props.now.getFullYear();
    return (
      <div id="percentageBox" style={{zIndex:1, color: 'white'}}>
        <CurrentPercent now={this.props.now} type={this.state.type} />
        <button className={this.state.type === 'year' ? 'active' : ''} onClick={this.setType.bind(this, 'year')}>{currentYear}</button >
        <button className={this.state.type === 'day' ? 'active' : ''} onClick={this.setType.bind(this, 'day')}>today</button>
        <PercentageBoxPaperBackground />
      </div>
    )
  }
}

class CurrentPercentBase extends React.Component {
  componentDidUpdate() {
    this.props.setPercentage(this.getCurrentPercentage());
  }

  getCurrentPercentage() {
    let percentage;
    if(this.props.type === 'year'){
      percentage = this.percentOfYear(this.props.now);
    } else {
      percentage = this.percentOfDay(this.props.now);
    }
    return percentage;
  }

  percentOfYear(now) {
    let start = new Date(now.getFullYear(), 0, 0);
    let diff = now - start;
    let oneYear = 1000 * 60 * 60 * 24 * 365;
    let percent = (diff / oneYear)*100;
  
    let toReturn = percent.toFixed(3);
    return toReturn;
  }
  
  percentOfDay(now) {
    let startOfDay = new Date(now);
    startOfDay.setHours(0);
    startOfDay.setMinutes(0);
    startOfDay.setSeconds(0);
    startOfDay.setMilliseconds(0);
    let diff = now - startOfDay;
    let percent = (diff/(24*60*60*1000))*100;
  
    let toReturn = percent.toFixed(3);
    return toReturn;
  }

  render() {    
    return (
      <div id="percentageBox-current-percent">
        <span>{ this.getCurrentPercentage() }%</span>
      </div>
    );
  }
};

const CurrentPercent = connect(null,{setPercentage})(CurrentPercentBase);

export default PercentageBox;