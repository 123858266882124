import { FETCH_USER, USER_SIGNED_IN } from '../actions/types';

export default (state = {}, action) => {
  switch( action.type ) {
    case FETCH_USER:
      return {
        ...state,
        loggedIn: action.userData ? true : false,
        uid: action.userData ? action.userData.uid : null,
      }  
    case USER_SIGNED_IN:
      return {
        ...state,
        loggedIn: action.userData ? true : false,
        uid: action.userData ? action.userData.uid : null,
      }
      
    default:
      return state;
  }
}