import React from 'react';

import Percentages from './Percentages';
import DaysOfWeek from './DaysOfWeek';
import PercentagePaperBackground from './PercentagePaperBackground';

import './TodayPage.css';

class TodayPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      now: new Date(),
      currentPercentage: 0
    };
  }

  componentDidMount(){
    document.title = 'Today';
    document.getElementById('favicon-link').href = "/img/today_favicon.png";
    this.intervalID = setInterval(()=> {
      this.setState({
        now: new Date()
      });
    },1000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalID);
  }

  render(){
    return (
      <div id="today-page">

        <DaysOfWeek now={this.state.now}/>
        
        <div id="percent">
          <Percentages now={this.state.now} />
        </div>

        <PercentagePaperBackground />
      </div>
    )
  }
};

export default TodayPage;