import React from 'react';

export const CancelIcon = (props) => {
    const { title } = props;
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title">
            <title id="title">{title || 'Cancel'}</title>
            <path d="M7.77295 7.001L13.8393 0.934579C14.0529 0.720998 14.0529 0.374717 13.8393 0.161163C13.6258 -0.0523915 13.2795 -0.0524188 13.0659 0.161163L6.99951 6.22758L0.933115 0.161163C0.719533 -0.0524188 0.373252 -0.0524188 0.159698 0.161163C-0.0538564 0.374744 -0.0538837 0.721025 0.159698 0.934579L6.22609 7.00097L0.159698 13.0674C-0.0538837 13.281 -0.0538837 13.6272 0.159698 13.8408C0.266475 13.9476 0.406448 14.001 0.54642 14.001C0.686392 14.001 0.826337 13.9476 0.933142 13.8408L6.99951 7.77441L13.0659 13.8408C13.1727 13.9476 13.3126 14.001 13.4526 14.001C13.5926 14.001 13.7325 13.9476 13.8393 13.8408C14.0529 13.6272 14.0529 13.2809 13.8393 13.0674L7.77295 7.001Z" fill="currentColor"/>
        </svg>
    )
}

export const TrashIcon = (props) => {
    const { title } = props;
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title">
            <title id="title">{title || 'Trash'}</title>
            <g clipPath="url(#clip0)">
                <path d="M10.2067 5.79688C9.99977 5.79688 9.83203 5.96462 9.83203 6.17158V13.2535C9.83203 13.4604 9.99977 13.6283 10.2067 13.6283C10.4137 13.6283 10.5814 13.4604 10.5814 13.2535V6.17158C10.5814 5.96462 10.4137 5.79688 10.2067 5.79688Z" fill="currentColor"/>
                <path d="M5.78535 5.79688C5.57838 5.79688 5.41064 5.96462 5.41064 6.17158V13.2535C5.41064 13.4604 5.57838 13.6283 5.78535 13.6283C5.99232 13.6283 6.16006 13.4604 6.16006 13.2535V6.17158C6.16006 5.96462 5.99232 5.79688 5.78535 5.79688Z" fill="currentColor"/>
                <path d="M2.56276 4.76335V13.9953C2.56276 14.541 2.76285 15.0534 3.11238 15.4211C3.4603 15.7898 3.94449 15.9991 4.45122 16H11.5408C12.0477 15.9991 12.5318 15.7898 12.8796 15.4211C13.2292 15.0534 13.4292 14.541 13.4292 13.9953V4.76335C14.1241 4.57893 14.5743 3.90768 14.4813 3.19471C14.3883 2.48189 13.781 1.94867 13.062 1.94852H11.1435V1.48014C11.1457 1.08626 10.99 0.708039 10.7112 0.42979C10.4323 0.151688 10.0535 -0.0031709 9.65964 4.92333e-05H6.33236C5.93848 -0.0031709 5.55968 0.151688 5.28085 0.42979C5.00201 0.708039 4.84627 1.08626 4.84847 1.48014V1.94852H2.93C2.21104 1.94867 1.60375 2.48189 1.51066 3.19471C1.41771 3.90768 1.86795 4.57893 2.56276 4.76335V4.76335ZM11.5408 15.2506H4.45122C3.81056 15.2506 3.31217 14.7002 3.31217 13.9953V4.79629H12.6798V13.9953C12.6798 14.7002 12.1814 15.2506 11.5408 15.2506ZM5.59788 1.48014C5.59539 1.28503 5.67209 1.09724 5.81056 0.959502C5.94888 0.821768 6.13711 0.746095 6.33236 0.749461H9.65964C9.85489 0.746095 10.0431 0.821768 10.1814 0.959502C10.3199 1.09709 10.3966 1.28503 10.3941 1.48014V1.94852H5.59788V1.48014ZM2.93 2.69793H13.062C13.4345 2.69793 13.7365 2.99989 13.7365 3.3724C13.7365 3.74491 13.4345 4.04688 13.062 4.04688H2.93C2.55749 4.04688 2.25553 3.74491 2.25553 3.3724C2.25553 2.99989 2.55749 2.69793 2.93 2.69793V2.69793Z" fill="currentColor"/>
                <path d="M7.99629 5.79688C7.78932 5.79688 7.62158 5.96462 7.62158 6.17158V13.2535C7.62158 13.4604 7.78932 13.6283 7.99629 13.6283C8.20325 13.6283 8.37099 13.4604 8.37099 13.2535V6.17158C8.37099 5.96462 8.20325 5.79688 7.99629 5.79688Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const SendIcon = (props) => {
    const { title } = props;
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title">
            <title id="title">{title || 'Send'}</title>
            <path d="M16.461 0.072357L0.748167 5.85405C-0.226936 6.21285 -0.256045 7.58134 0.70292 7.98128L3.59529 9.18754L14.8298 2.82102L5.69726 10.1459V13.4676C5.69726 14.6003 7.16801 15.0429 7.79309 14.0982L8.78116 12.605C9.09199 12.1353 9.69874 11.9621 10.2107 12.197L13.18 13.5592C13.818 13.8519 14.5666 13.5056 14.7566 12.8298L17.9557 1.45422C18.2083 0.556157 17.3365 -0.249798 16.461 0.072357Z" fill="currentColor"/>
        </svg>
    )
}

export const EditIcon = (props) => {
    const { title } = props;
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title">
            <title id="title">{title || 'Edit'}</title>
            <path d="M13.7735 1.09887C12.9671 0.293865 11.6611 0.293865 10.8547 1.09887L10.1241 1.83351L2.34658 9.60688L2.33005 9.62354C2.32605 9.62754 2.32605 9.6318 2.32179 9.6318C2.31352 9.6442 2.30113 9.65647 2.29299 9.66886C2.29299 9.673 2.28873 9.673 2.28873 9.67713C2.28046 9.68953 2.27646 9.69779 2.26807 9.71019C2.26406 9.71432 2.26406 9.71832 2.25993 9.72258C2.2558 9.73498 2.25167 9.74324 2.24741 9.75564C2.24741 9.75964 2.2434 9.75964 2.2434 9.76391L0.51781 14.953C0.46719 15.1007 0.505671 15.2643 0.616854 15.3739C0.694979 15.451 0.800351 15.4941 0.909984 15.4938C0.954793 15.493 0.999214 15.486 1.04209 15.4731L6.22713 13.7434C6.23114 13.7434 6.23114 13.7434 6.2354 13.7394C6.24844 13.7355 6.26097 13.7299 6.27246 13.7227C6.27569 13.7223 6.27853 13.7209 6.28085 13.7187C6.29312 13.7104 6.30965 13.7021 6.32204 13.6938C6.33431 13.6857 6.34684 13.6733 6.35924 13.665C6.36337 13.6607 6.36737 13.6607 6.36737 13.6567C6.37163 13.6526 6.3799 13.6486 6.38403 13.6402L14.8922 5.13206C15.6972 4.32563 15.6972 3.01972 14.8922 2.21342L13.7735 1.09887ZM6.09503 12.7732L3.22184 9.90014L10.4131 2.7089L13.2863 5.58196L6.09503 12.7732ZM2.81714 10.6638L5.32721 13.1738L1.5581 14.4287L2.81714 10.6638ZM14.3102 4.55407L13.8725 4.99583L10.9992 2.12251L11.4411 1.68087C11.9244 1.19792 12.7079 1.19792 13.1914 1.68087L14.3142 2.80368C14.7939 3.28922 14.7921 4.07085 14.3102 4.55407Z" fill="currentColor"/>
        </svg>    
    )
}

