import React from 'react';
import {GoalList} from '.';

const MyGoals = () => {
  return (
    <div id="my-goals">
      <GoalList />
    </div>
  )
}
export default MyGoals;