import { authRef, googleAuthProvider } from "../config/firebase";
import { FETCH_USER, USER_SIGNED_IN, USER_SIGNED_OUT } from "./types";

export const signIn = () => dispatch => {
  authRef
    .signInWithPopup(googleAuthProvider)
    .then(userData => {
      dispatch({
        type: USER_SIGNED_IN,
        userData
      })
    })
    .catch(error => {
      console.log(error);
    });
};

export const signOut = () => dispatch => {
  authRef
    .signOut()
    .then(()=>{
      dispatch({
        type: USER_SIGNED_OUT
      })
    })
    .catch(error => {
      console.log(error);
    });
};

export const fetchUser = () => dispatch => {
  authRef.onAuthStateChanged(user => {
    if(user) {
      dispatch({
        type: FETCH_USER,
        userData: user
      });
    } else {
      dispatch({
        type: FETCH_USER,
        userData: null
      });
    }
  });
};