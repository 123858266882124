import React from 'react';

const DaysOfWeek = (props) => {
  return (
    <div id="days-of-week" className="today-grid">
      <DayOfWeek now={props.now} offset={-1}/>
      <DayOfWeek now={props.now} offset={0}/>
      <DayOfWeek now={props.now} offset={1}/>
      <DayOfWeek now={props.now} offset={2}/>
    </div>
  );
}

const DayOfWeek = (props) => {
  let dayStats;

  if( props.offset === 0 ){
    dayStats = (
      <div className="stats">
        <div>
          <WhatDayIsIt today={props.now}/>
          <TodayDate today={props.now}/>
        </div>

        <div>
          <h3>Kick today's butt.</h3>
        </div>
      </div>
    );
  }

  return (
    <div className={props.offset === 0 ? "today heading" : "heading"}>
      <TodayDay today={props.now} offset={props.offset} />
      <NumberedDay {...props} />
      { dayStats }
    </div>
  )
}

const WhatDayIsIt = (props) => {
  let today = props.today;
  let months = ["January","February",'March','April','May', 'June','July','August','September','October','November','December'];
  let monthDayYear = months[parseInt(today.getMonth())]+' '+today.getDate()+", "+today.getFullYear();
  return <p>{monthDayYear}</p>;
}

const TodayDate = (props) =>{
  let today = props.today;
  let date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
  return <p>{date}</p>;
}

const TodayDay = (props) => {
  let today = props.today;
  let offset = props.offset ? Number(props.offset):0;
  let day = today.getDay();
  let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let dayOfWeek = daysOfWeek[(day+offset)%daysOfWeek.length];
  return <span>{dayOfWeek}</span>;
}

const NumberedDay = (props) => {
  let dayNumber = calcDayOfYear(props.now);
  let offset = props.offset ? Number(props.offset):0;
  let weekdayNumber = dayNumber + offset;
  return <span>{weekdayNumber}</span>;
}

function calcDayOfYear(day) {
  let start = new Date(day.getFullYear(), 0, 0);
  let diff = day - start;
  let oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export default DaysOfWeek;