import React, { Component } from 'react';
import { connect } from 'react-redux';

import { signOut } from '../../actions/auth';

class SignOutButton extends Component {
  render() {
    return (
      <button type="button" onClick={this.props.signOut}>
        Sign Out
      </button>
    );
  }
} 

export default connect(null, { signOut })(SignOutButton);
