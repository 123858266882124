import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

if(!firebase.apps.length){
  firebase.initializeApp(config);
}

export const firebaseObj = firebase;

/* AUTH */
export const authRef = firebase.auth();

authRef.setPersistence(process.env.NODE_ENV === 'test' 
  ? firebase.auth.Auth.Persistence.NONE 
  : firebase.auth.Auth.Persistence.SESSION);
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    
/* DATA */
const firestore = firebase.firestore();
export const usersRef = firestore.collection('users');
export const goalsRef = firestore.collection('goals');
export const ticksRef = firestore.collection('ticks');
export const workspacesRef = firestore.collection('workspaces');
export const currentWorkspaceRef = workspacesRef.doc('5d0xkor19nOOK712xvEv');