import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MyGoals, AddGoalButton, ViewCompletedButton } from '../Goal';
import PercentageBox from '../PercentageBox';
import AddGoal from '../Goal/AddGoal';

import './style.css';

class HomePage extends Component {
  
  showModal = () => {
    this.setState({ show: true})
  };
  hideModal = () => {
    this.setState({ show: false})
  };
  constructor(props){
    super(props);
    this.state = {
      now: new Date(),
      currentPercentage: 0,
      show: false
    };
  }

  componentDidMount(){
    document.title = 'Goals';
    document.getElementById('favicon-link').href = "/goals_favicon.png";
    
    this.intervalID = setInterval(()=> {
      this.setState({
        now: new Date()
      });
    },1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  
  render() {
    return (
      <div className="content-container" id="homePage">
        <MyGoals />
        <div id="bottom-right">
          <AddGoal show={this.state.show} handleClose={this.hideModal} />
          <div id="buttons" >
            <AddGoalButton handleOpen={this.showModal}/>
            <ViewCompletedButton />
          </div>
          <PercentageBox now={this.state.now} />
        </div>
      </div>
    )
  }
}

function mapStateToProps({auth}) {
  return {
    authenticated: auth.loggedIn
  }
}
export default connect(mapStateToProps)(HomePage);