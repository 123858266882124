import React, { Component } from "react";
import { connect } from 'react-redux';
import { DateInput } from '@progress/kendo-react-dateinputs';

import { currentWorkspaceRef } from '../../config/firebase';
import { addGoal } from '../../actions/goals';

class AddGoalForm extends Component {
  constructor() {
    super();
    let dDate = new Date()
    dDate.setDate(dDate.getDate()+60)
    this.state = {
      title: "",
      description: "",
      dueDate: dDate
    };    
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });    
  }
  updateDateInput = e => {
    console.log(e)
  }
  submitAddGoal = e => {
    e.preventDefault();
    let createdDate = new Date();
    console.log(this.state.dueDate)
    const goalToAdd = {
      workspace: currentWorkspaceRef,
      title: this.state.title,
      owner: this.props.user.uid,
      description: this.state.description,
      completed: false,
      completedDate: null,
      createdDate: createdDate,
      dueDate: this.state.dueDate,
      deleted: false,
      ticks: []
    };
    const { addGoal } = this.props;
    addGoal(goalToAdd);
    this.props.handleClose()
  }
  render() {
    if (!this.props.show){
      return null
    }
    return (
      <div id="addGoal-modal">
        <div id="modal-left">
          <button className="plussign" onClick={this.submitAddGoal}><img src="/img/plussign.png" alt="add goal" /></button>
          <button onClick={this.props.handleClose}><img className="trashcan" src="/img/trashcan.png" alt="delete" /></button>
        </div>
        <div id="modal-right">
          <div id="new-goal-title-input-div">
            <input 
            id="new-goal-title"
            type="text"
            name="title"
            // placeholder="Title"
            onChange={this.updateInput}
            value={this.state.title}
            />
            <label htmlFor="new-goal-title">Title</label>
          </div>
          
          <div id="new-goal-due-date-input-div">
            <DateInput className="date-input"
              value={this.state.dueDate}
              onChange={this.updateInput}
              name="dueDate"
            />
            <label htmlFor="new-goal-due-date">End Date</label>
          </div>
          <div id="new-goal-description-input-div">
            <input 
              id="new-goal-description" 
              type="text" 
              name="description"
              // placeholder="Description (optional)"
              onChange={this.updateInput}
              value={this.state.description}
            />
            <label htmlFor="new-goal-description">Description (optional)</label>
          </div>
        </div>
     
      </div>
    );
  }
}

function mapStateToProps({auth}) {
  
  return {
    user: auth
  }
}

export default connect(mapStateToProps, { addGoal })(AddGoalForm);