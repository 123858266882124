import React from 'react';

const ButtonImg = (props) => {
  const { icon, disabled, clickHandler, labelText } = props;
  return (
    <button className={"button-image " + (disabled ? 'disabled ' : '')} disabled={disabled || undefined} onClick={clickHandler}>
      <div>
        {icon}
      </div>
      {
        labelText && (
          <span>
            {labelText}
          </span>
        )
      }
    </button>
  )
}

export default ButtonImg;