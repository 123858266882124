import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as ROUTES from '../constants/routes';


export default function(ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentDidUpdate(nextProps) {
      this.checkAuth(nextProps.authenticated)
    }

    componentDidMount() {
      this.checkAuth(this.props.authenticated);
    }

    checkAuth(authToCheck){
      if(!authToCheck) {
        this.context.router.history.push(ROUTES.SIGN_IN);
      }
    }

    render() {
      if( this.props.authenticated ) {
        return <ComposedComponent {...this.props} />;
      }
      return null;
    }
  }

  function mapStateToProps({auth}) {
    return { 
      authenticated: auth.loggedIn 
    };
  }

  return connect(mapStateToProps)(Authentication);
}