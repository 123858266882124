export const fbToJsDate = (fbDateIn) => {
    return new Date(fbDateIn.seconds * 1000);
}

export const getDateString = (inJsDate, dateType) => {
    let inJsDateMonth = inJsDate.getMonth() + 1
    if (inJsDateMonth < 10) {
      inJsDateMonth = "0" + inJsDateMonth
    }
    let inJsDateDate = inJsDate.getDate()
    if (inJsDateDate < 10) {
      inJsDateDate = "0" + inJsDateDate
    }

    let inJsDateYear = inJsDate.getFullYear();

    switch (dateType) {
      case "long":
        return `${inJsDateMonth}/${inJsDateDate}/${inJsDateYear % 100}`;
      default:
        break;
    }
    return inJsDateMonth + "/" + inJsDateDate;
  }