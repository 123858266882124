import React from 'react';
import { connect } from 'react-redux';

import { getTick } from '../../actions/goals';
import { fbToJsDate, getDateString } from '../../shared/helpers';


class GoalItemTick extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tick: null,
      loading: true,
      error: ''
    }
  }

  componentDidMount() {
    this.props.getTick(this.props.tick).then((doc) => {
      if (doc.exists) {
        this.setState({
          tick: doc.data(),
          loading: false
        });
      } else {
        this.setState({
          error: 'No such document',
          loading: false
        })
      }
    }).catch((error) => {
      this.setState({
        error: error,
        loading: false
      })
    });
  }

  render() {
    const {tick} = this.state;
    console.log(this.state.tick);
    if(!tick){
      return null;
    }

    return (
      <li className="activity">
        <span className="tick-date">{getDateString(fbToJsDate(tick.date))}</span>
        <span>{tick.description || 'no desc'}</span>
      </li>
    );
  }
}


export default connect(null, { getTick })(GoalItemTick);