import React from 'react';
import paper from 'paper';
import { connect } from 'react-redux';


import './PercentagePaperBackground.css';

class PercentagePaperBackground extends React.Component {
  componentDidMount() {
    paper.setup(document.getElementById('paperCanvas'));
    
    var values = {
      friction: 0.2,
      timeStep: 0.05,
      amount: 20,
      mass: 6,
      count: 0,
      strength: 0.1
    };
    values.invMass = 1 / values.mass;

    var path, springs;
    var size = paper.view.size;
    size.width *= 2;
    size.height *= 1.2;
    
    var Spring = function(a, b, strength, restLength) {
      this.a = a;
      this.b = b;
      this.restLength = restLength || size.width / (values.amount * 2);
      this.strength = strength ? strength : 0.55;
      this.mamb = values.invMass * values.invMass;
    };

    Spring.prototype.update = function() {
      var delta = this.b.subtract(this.a);
      var dist = delta.length;
      var normDistStrength = (dist - this.restLength) /
          (dist * this.mamb) * this.strength;
      delta.y *= normDistStrength * values.invMass * 0.1;
      if (!this.a.fixed){
        let randomFactor = 0.2;
        delta.y += (Math.random()*randomFactor) - (randomFactor/2);
        this.a.y += delta.y;
      }
      if (!this.b.fixed)
        this.b.y -= delta.y;
    };


    function createPath(strength) {
      var path = new paper.Path({
        fillColor: 'rgba(17,26,61,1)'
      });
      springs = [];
      
      for (var i = 0; i <= values.amount; i++) {
        let pointToAdd = new paper.Point(i / values.amount, 0.08);
        pointToAdd.x *= size.width;
        pointToAdd.y *= size.height;
        var segment = path.add(pointToAdd);        

        var point = segment.point;
        if (i === 0 || i === values.amount){
          point.y += size.height;
        }
        point.fixed = i < 2 || i > values.amount - 2;
        
        if( !point.fixed ){
          point.y *= Math.random();
        }
        
        point.px = point.x;
        point.py = point.y;
        if (i > 0) {
          var spring = new Spring(segment.previous.point, point, strength);
          springs.push(spring);
        }
      }
      path.position.x -= size.width / 4;
      return path;
    }

    paper.view.onResize = () => {
      if (path)
        path.remove();
      size = paper.view.bounds.size;
      size.width *= 2;
      size.height *= 1.2;
      path = createPath(values.strength);
    }

    paper.view.onFrame = (event) => {
      if(!path){
        path = createPath(values.strength);
      }

      updateWave(path);
    }

    paper.view.onMouseMove = (event) => {
      var location = path.getNearestLocation(event.point);
      var segment = location.segment;
      var point = segment.point;
    
      if (!point.fixed && location.distance < size.height / 4) {
        var y = event.point.y;
        point.y += (y - point.y) / 6;
        if (segment.previous && !segment.previous.fixed) {
          var previous = segment.previous.point;
          previous.y += (y - previous.y) / 6;
        }
        if (segment.next && !segment.next.fixed) {
          var next = segment.next.point;
          next.y += (y - next.y) / 6;
        }
      }
    }

    function updateWave(path) {
      var force = 1 - values.friction * values.timeStep * values.timeStep;
      for (let i = 0, l = path.segments.length; i < l; i++) {
        let point = path.segments[i].point;
        let dy = (point.y - point.py) * force;
        point.py = point.y;
        point.y = Math.max(point.y + dy, 0);
      }

      for (let j = 0, l = springs.length; j < l; j++) {
        springs[j].update();
      }
      path.smooth({ type: 'continuous' });
    }
  }

  render() {
    let canvasHeight = 110;
    let transformPercentage;

    if(!this.props.percentage){
      transformPercentage = 10;
    } else {
      transformPercentage = this.props.percentage;
    }
    transformPercentage = (100 - transformPercentage) * .90;
    
    const canvasStyle = {
      transform: 'translateY(' + transformPercentage + '%)',
      height: canvasHeight + '%'
    };

    return (
      <div id="PercentagePaperBackground">
        <canvas id="paperCanvas" resize="true" style={canvasStyle}></canvas>
      </div>
    );
  }
}

function mapStateToProps(state) {  
  return { 
    percentage: state.dayStats.percentage
  }
}

export default connect(mapStateToProps)(PercentagePaperBackground);