import './style.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import TodayBlob from './TodayBlob';


class Navigation extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <nav id="nav-container">
          { this.props.isLoggedIn ? 
            <NavigationAuth /> : 
            <NavigationNonAuth />
          }
        </nav>
        <div id="today-blob-container">
          <TodayBlob/>
        </div>
      </div>
    );
  }
}

const NavigationAuth = () => (
  <ul id="navAuth">
    <li id="today-link">
      <NavLink exact={true} activeClassName="active" to={ROUTES.TODAY}>Today</NavLink>
    </li>
    <li>
      <NavLink exact={true} activeClassName="active" to={ROUTES.HOME}>Goals</NavLink>
    </li>
    <li>
      <NavLink activeClassName="active" to={ROUTES.ACCOUNT}>Account</NavLink>
    </li>
    <li>
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => (
  <ul id="navNonAuth">
     <li id="today-link">
      <NavLink exact={true} activeClassName="active" to={ROUTES.TODAY}>Today</NavLink>
    </li>
    
    <li>
      <NavLink activeClassName="active" to={ROUTES.HOME}>Goals</NavLink>
    </li>
  </ul>
);

function mapStateToProps({auth}) {  
  return { 
    isLoggedIn: auth.loggedIn
  }
}

export default connect(mapStateToProps)(Navigation);
