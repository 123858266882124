import React from 'react';
import { connect } from 'react-redux';

import {addTick} from '../../actions/goals';

class Ticks extends React.Component {
  addTick = () => {
    this.props.addTick(this.props.goalID);
  }

  render() {
    let jsxToReturn = []
    const tickLength = this.props.ticks ? this.props.ticks.length : 0;

    if(tickLength === 0){
      jsxToReturn.push(<div className='add-tick' key='a'>add activity</div>)
    }
    
    for(let i = 0; i < tickLength; i++){
      jsxToReturn.push(<div key={i} className="tick"></div>)
    } 
    
    return <div className="ticks grid-left" tabIndex={0} onClick={this.addTick.bind(this)} >{jsxToReturn}</div>
  }
}

export default connect(null, {addTick})(Ticks);